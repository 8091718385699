import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { decodeHtml } from '@helpers/string-helper'
import { getStoreName, getStoreInfoFromFeed, getStoreSlug } from '@helpers/store-locator'
import { expandState, getRegionZone } from '@helpers/geo-location'
import { setupAnalytics } from '@helpers/google-tag-manager'
import GoogleMap from '@components/google-map'
import Breadcrumb from '@templates/strapi-cms/content-types/Breadcrumbs'
import Layout from '@components/layout'
import '@assets/css/pages/store-location.sass'
import StoreNeighborhoods from '@components/store-details/store-neighborhoods'
import StoreDirections from '@components/store-details/store-directions'
import StoreDescription from '@components/store-details/store-description'
import StoreFaqs from '@components/store-details/store-faqs'
import StoreHours from '@components/store-details/store-hours'
import StoreImage from '@components/store-details/store-image'
import StoreSeo from '@components/store-details/store-seo'
import InStoreAppointment from '@components/store-details/in-store-appointment'
import StoreLocationInfo from '@components/store-details/store-location-info'
import PaymentOptions from '@components/store-details/payment-options'
import {
  StoreDetailsPageColumn1,
  StoreDetailsPageColumn2,
  StoreDetailsPageColumnWrapper,
  StoreDetailsWrapper,
  StyledMapsWrapper,
} from '@components/store-details/styles'
import StoreTitle from '@components/store-details/store-title'
import SpecialClosings from '@components/store-details/special-closings'
import Banner from './content-types/Banner'

const regionInPR = getRegionZone().region === 'PR'

const breadcrumbsStyles = {
  paddingBottom: '16px',
}

const createCrumb = (label, url) => ({
  category: label,
  id: url,
  url,
  label,
})

const generateStoreCrumbs = store => [
  createCrumb('Find a Showroom', '/stores'),
  ...(store?.State
    ? [
        createCrumb(
          `Find a Showroom - ${expandState(store.State)}`,
          `/stores/${expandState(store.State)
            .toLowerCase()
            .replace(' ', '-')}`,
        ),
      ]
    : []),
  createCrumb(
    decodeHtml(`${store.City}, ${store.State}${store.StoreName ? ` (${store.StoreName})` : ''}`),
    getStoreSlug(store),
  ),
]

const Store = ({
  data,
  google,
  rtg_location,
  order,
  shipping_address,
  cart,
  isLandscape,
  onSetCart,
  onSetLocation,
  onSetOrder,
  onSetShippingAddress,
}) => {
  const [width, setWidth] = useState()

  const store = data.strapiStore
  const storeInfoFromFeed = getStoreInfoFromFeed(store?.StoreNumber)
  const breadCrumbs = useMemo(() => generateStoreCrumbs(store), [store])

  useEffect(() => {
    setupAnalytics({ pageData: { type: 'store', title: getStoreName(store), path: window.location.pathname } })
    window.addEventListener('resize', windowResized)
    setWidth(window.innerWidth)
    return () => window.removeEventListener('resize', windowResized)
  }, [store])

  const windowResized = () => {
    setTimeout(() => {
      setWidth(window.innerWidth)
    }, 100)
  }

  const renderBanner = (banner, isMobile) => {
    if (banner) {
      return <Banner data={banner} isMobile={isMobile} />
    }
    return null
  }

  const getStoreTypeTitle = () => {
    let storeTypeTitle = `${store.City}, ${store.State}`
    if (store.StoreType === 'Showroom' || store.StoreType === 'Express') {
      storeTypeTitle = `${storeTypeTitle} Furniture & Mattress Store`
    } else if (store.StoreType === 'Kids') {
      storeTypeTitle = `${storeTypeTitle} Kids Furniture & Mattress Store`
    } else if (store.StoreType === 'Outlet') {
      storeTypeTitle = `${storeTypeTitle} Affordable Furniture Outlet Store`
    } else if (store.StoreType === 'Outdoor') {
      storeTypeTitle = `${storeTypeTitle} Patio Furniture: Wicker, Teak Outdoor Furniture Sets`
    }
    return storeTypeTitle
  }

  if (!store) return null
  const storeTypeTitle = getStoreTypeTitle()
  return (
    <Layout
      rtg_location={rtg_location}
      order={order}
      shipping_address={shipping_address}
      cart={cart}
      isLandscape={isLandscape}
      onSetCart={onSetCart}
      onSetLocation={onSetLocation}
      onSetOrder={onSetOrder}
      onSetShippingAddress={onSetShippingAddress}
    >
      <Helmet title={storeTypeTitle} />
      {width <= 1024 ? (
        <StoreDetailsWrapper>
          <StoreSeo seo={store.SEO.SEO} storeName={store.StoreName} storeTypeTitle={storeTypeTitle} />
          <StoreDetailsPageColumn1>
            <div className="stores-breadcrumb" style={breadcrumbsStyles}>
              <Breadcrumb storeBreadcrumbs={breadCrumbs} />
            </div>
            <StoreTitle storeTypeTitle={storeTypeTitle} />
            <SpecialClosings specialClosings={store.SpecialClosings} />
            {renderBanner(store.Banner, true)}
            <StyledMapsWrapper className="wrapper">
              <GoogleMap store={store} google={google} pageContext={store} zoom={11} className="wrapper" />
            </StyledMapsWrapper>
            <StoreLocationInfo store={store} storeInfoFromFeed={storeInfoFromFeed} storeTypeTitle={storeTypeTitle} />
            {!regionInPR && <InStoreAppointment store={store} />}
            <StoreImage storeImage={store.StoreImage} storeTypeTitle={storeTypeTitle} />
            <StoreHours storeInfoFromFeed={storeInfoFromFeed} />
            <StoreDescription description={store.Description} />
            <StoreFaqs faqs={store.FAQs} />
            <StoreDirections directions={store.Directions} />
            <StoreNeighborhoods neighborhoodsNearby={store.NearbyNeighborhoods} />
            <PaymentOptions storeType={store.StoreType} storeState={store.State} />
          </StoreDetailsPageColumn1>
        </StoreDetailsWrapper>
      ) : (
        <>
          <StoreSeo seo={store.SEO.SEO} storeName={store.StoreName} storeTypeTitle={storeTypeTitle} />
          <div className="stores-breadcrumb" style={breadcrumbsStyles}>
            <Breadcrumb storeBreadcrumbs={breadCrumbs} />
          </div>
          <StoreDetailsWrapper>
            <StoreTitle storeTypeTitle={storeTypeTitle} />
            {renderBanner(store.Banner, false)}
            <StoreDetailsPageColumnWrapper>
              <StoreDetailsPageColumn1>
                <StoreImage storeImage={store.StoreImage} storeTypeTitle={storeTypeTitle} />
                <StoreHours storeInfoFromFeed={storeInfoFromFeed} />
                <InStoreAppointment store={store} />
                <StoreNeighborhoods neighborhoodsNearby={store.NearbyNeighborhoods} />
                <StoreDirections directions={store.Directions} />
                <PaymentOptions storeType={store.StoreType} storeState={store.State} />
              </StoreDetailsPageColumn1>
              <StoreDetailsPageColumn2>
                <SpecialClosings specialClosings={store.SpecialClosings} />
                <StoreLocationInfo
                  store={store}
                  storeInfoFromFeed={storeInfoFromFeed}
                  storeTypeTitle={storeTypeTitle}
                />
                <StoreDescription description={store.Description} />
                <StoreFaqs faqs={store.FAQs} />
                <StyledMapsWrapper className="wrapper">
                  <GoogleMap store={store} google={google} pageContext={store} zoom={11} className="wrapper" />
                </StyledMapsWrapper>
              </StoreDetailsPageColumn2>
            </StoreDetailsPageColumnWrapper>
          </StoreDetailsWrapper>
        </>
      )}
    </Layout>
  )
}

Store.propTypes = {
  data: PropTypes.shape({
    strapiStore: PropTypes.shape({
      Banner: PropTypes.object,
      City: PropTypes.string,
      Description: PropTypes.object,
      Directions: PropTypes.object,
      FAQs: PropTypes.object,
      NearbyNeighborhoods: PropTypes.array,
      SpecialClosings: PropTypes.object,
      State: PropTypes.string,
      StoreImage: PropTypes.object,
      StoreName: PropTypes.string,
      StoreNumber: PropTypes.number,
      StoreType: PropTypes.string,
      SEO: PropTypes.object,
    }),
  }),
  google: PropTypes.object,
  rtg_location: PropTypes.object,
  order: PropTypes.object,
  shipping_address: PropTypes.string,
  cart: PropTypes.shape({
    cartItems: PropTypes.array,
  }),
  isLandscape: PropTypes.bool,
  onSetCart: PropTypes.func,
  onSetLocation: PropTypes.func,
  onSetOrder: PropTypes.func,
  onSetShippingAddress: PropTypes.func,
}

export default Store

export const query = graphql`
  query($storeID: String) {
    strapiStore(id: { eq: $storeID }) {
      id
      Banner {
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      StoreNumber
      StoreName
      StoreType
      Address1
      Address2
      City
      State
      Zip
      StoreImage {
        url
        hash
      }
      Location {
        latLng {
          lat
          lng
        }
      }
      PhoneNumber
      StoreHours {
        mondayClosed
        mondayOpen
        tuesdayClosed
        tuesdayOpen
        wednesdayClosed
        wednesdayOpen
        thursdayClosed
        thursdayOpen
        fridayClosed
        fridayOpen
        saturdayClosed
        saturdayOpen
        sundayClosed
        sundayOpen
      }
      Description {
        childMarkdownRemark {
          html
        }
      }
      Directions {
        childMarkdownRemark {
          html
        }
      }
      NearbyNeighborhoods {
        Name
      }
      InStoreAppointments
      SeniorAppointments
      RegularAppointments
      Payonline
      FAQs {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      SpecialClosings {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      SEO {
        SEO {
          id
          PageTitle
          PageHeading
          Canonical
          MetaRobots
          MetaDescription
          LD_Schema {
            address {
              _type
              addressCountry
              addressLocality
              addressRegion
              postalCode
              streetAddress
            }
            contactPoint {
              _type
              contactType
              telephone
            }
            geo {
              _type
              latitude
              longitude
            }
            potentialAction {
              _type
              query_input
              target
            }
            _type
            _context
            areaServed
            description
            hasMap
            image
            logo
            name
            paymentAccepted
            openingHours
            priceRange
          }
          strapiId
        }
      }
    }
  }
`
